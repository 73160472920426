import React, { useState, useEffect } from 'react';
import { uploadFile, writeObjectToFirestore } from '../firebase'; // Adjust the path as necessary
import { v4 as uuidv4 } from 'uuid';

const FramesUpload = ({ togglePopup, frame = null, onComplete }) => {
    const [image, setImage] = useState(null);
    const [imageP, setImageP] = useState(null);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [netoWidth, setNetoWidth] = useState('');
    const [uniqueId, setUniqueId] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(frame){
            setImage(frame.url);
            setWidth(frame.width);
            setHeight(frame.height);
            setName(frame.name);
            setSku(frame.sku);
            setNetoWidth(frame.netoWidth);
            setUniqueId(frame.uniqueId);
        }
    }, []);
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            setSelectedFile(e.target.files[0]);
            // show the image preview
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageP(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
            if (selectedFile && image) {
                const id = !frame ? uuidv4() : frame.uniqueId;
                if(!frame){
                    setUniqueId(id);
                }
                
                const fileEnd = selectedFile.name.split('.').pop();
                const downloadURL = await uploadFile('frames', image, id + "." + fileEnd);
                
                const frame_object = {
                    name: name,
                    sku: sku,
                    netoWidth: netoWidth,
                    width: width,
                    height: height,
                    uniqueId: id,
                    url: downloadURL
                };
                
                await writeObjectToFirestore('frames', id, frame_object);
                console.log('Image uploaded successfully:', downloadURL);
                window.fetchFrames();
                // Clear form
                setImage(null);
                setSelectedFile(null);
                setWidth('');
                setHeight('');
                setName('');
                setSku('');
                setNetoWidth('');
                setUniqueId('');
                
            } else if(frame) {
                const frame_object = {
                    name: name,
                    sku: sku,
                    netoWidth: netoWidth,
                    width: width,
                    height: height,
                    uniqueId: uniqueId,
                    url: image
                };
                
                await writeObjectToFirestore('frames', uniqueId, frame_object);
                console.log('Frame updated successfully');
            }
            
            
            // Close popup after everything is done
            togglePopup();
            
        } catch (error) {
            console.error('Error during upload/update:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center p-8 flex-col">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div className="bg-blue-500 h-2.5 rounded-full w-full animate-pulse"></div>
                </div>
                <p className="mt-2 text-center">Please wait...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 flex">
            {/* Left side - Image section */}
            <div className="w-1/3 p-2">
                <label className="mb-1 font-semibold">Image:</label>
                {imageP && (<img src={imageP} alt="Frame" className="w-full object-contain" />)}
                {image && !imageP && (<img src={image} alt="Frame" className="w-full object-contain" />)}
                <input type="file" onChange={handleImageChange} required={frame ? false : true} className="p-2 border rounded image_c w-full" />
            </div>

            {/* Right side - Input fields */}
            <div className="w-2/3 p-2 grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                    <label className="mb-1 font-semibold">Width:</label>
                    <input type="text" value={width} onChange={(e) => setWidth(e.target.value)} required className="p-2 border rounded width_c" />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 font-semibold">Height:</label>
                    <input type="text" value={height} onChange={(e) => setHeight(e.target.value)} required className="p-2 border rounded height_c" />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 font-semibold">Name:</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="p-2 border rounded name_c" />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 font-semibold">ID:</label>
                    <input type="text" value={sku} onChange={(e) => setSku(e.target.value)} required className="p-2 border rounded sku_c" />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1 font-semibold">Neto Width:</label>
                    <input type="text" value={netoWidth} onChange={(e) => setNetoWidth(e.target.value)} required className="p-2 border rounded netowidth_c" />
                </div>
                <input type="hidden" value={uniqueId} readOnly className="uniqueid_c" />
                
                <div className="col-span-2">
                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded mt-4">
                        {frame ? 'Update' : 'Create'}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FramesUpload;
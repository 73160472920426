import React, { useState, useEffect } from 'react';
import { readCollectionFromFirestore, deleteObjectFromFirestore } from '../firebase';

const BundlesList = ({ onBundleSelect, onEditBundle }) => {
const [bundles, setBundles] = useState([]);
const [selectedRow, setSelectedRow] = useState(null);

const handleRowClick = (bundle, index) => {
    onBundleSelect(bundle);
    setSelectedRow(index);
};

const handleDeleteBundle = async (e, bundleId) => {
    e.stopPropagation(); // Prevent row click event from firing
    try {
        await deleteObjectFromFirestore('bundles', bundleId);
        // Update local state to remove the deleted bundle
        setBundles(bundles.filter(bundle => bundle.id !== bundleId));
    } catch (error) {
        console.error('Error deleting bundle:', error);
    }
};

useEffect(() => {
    const fetchBundles = async () => {
    try {
        const files = await readCollectionFromFirestore('bundles');
        setBundles(files);
    } catch (error) {
        console.error('Error fetching bundles:', error);
    }
    };
    fetchBundles();
}, []);

return (
    <div className="w-full h-full">
        <div className="relative h-full">
            <table className="min-w-full bg-white">
                <thead className="sticky top-0 bg-white z-10">
                    <tr>
                        <th className="py-2 px-4 bg-gray-200">Name</th>
                        <th className="py-2 px-4 bg-gray-200">SKU</th>
                        <th className="py-2 px-4 bg-gray-200">Actions</th>
                    </tr>
                </thead>
                <tbody className="overflow-y-auto">
                    {bundles.map((bundle, index) => (
                        <tr 
                            key={index} 
                            className={`border-b cursor-pointer ${
                                selectedRow === index ? 'bg-blue-100' : 'hover:bg-gray-50'
                            }`}
                            onClick={() => handleRowClick(bundle, index)}
                        >
                            <td className="py-2 px-4">{bundle.name}</td>
                            <td className="py-2 px-4">{bundle.sku}</td>
                            <td className="py-2 px-4">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRowClick(bundle, index);
                                        setTimeout(() => {
                                            onEditBundle(bundle);
                                        }, 100); // 100ms delay
                                    }}
                                    className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-700 transition duration-200 mr-2"
                                >
                                    Edit
                                </button>
                                <button 
                                    onClick={(e) => handleDeleteBundle(e, bundle.id)}
                                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);
};

export default BundlesList;
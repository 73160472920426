import React from 'react';
import './Popup.css';

const Popup = ({ children, triggerText, isOpen, togglePopup, onComplete }) => {
    const handleClose = async () => {
        if (onComplete) {
            await onComplete();
        }
        togglePopup();
    };

    return (
        <div>
            <button onClick={togglePopup}>{triggerText}</button>
            {isOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-btn" onClick={handleClose}>X</button>
                        {React.cloneElement(children, { 
                            togglePopup: handleClose,
                            onComplete: onComplete 
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Popup;
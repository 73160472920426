// src/utils/auth.js
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase';

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    // Handle successful login (e.g., redirect to dashboard)
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    // Handle successful logout (e.g., redirect to login page)
  } catch (error) {
    throw error;
  }
};

export const getCurrentUser = () => {
  return auth.currentUser;
};
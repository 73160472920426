import React, { useState, useEffect } from 'react';
import FramesSlider from './FramesSlider'; // Adjust the import path as necessary
import { writeObjectToFirestore } from '../firebase'; // Adjust the import path as necessary
import { v4 as uuidv4 } from 'uuid';
import FramePlayground from './FramePlayground';
import BundlesList from './BundlesList';
import Popup from './Popup';

const BundleUpload = () => {
    const [selectedFrames, setSelectedFrames] = useState([]);
    const [imageSpecsWidth, setImageSpecsWidth] = useState([]);
    const [imageSpecsHeight, setImageSpecsHeight] = useState([]);
    const [imageSpecsTop, setImageSpecsTop] = useState([]);
    const [imageSpecsLeft, setImageSpecsLeft] = useState([]);
    const [netoWidthPositions, setNetoWidthPositions] = useState([]);
    const [showFramePlayground, setShowFramePlayground] = useState(false);
    const [sku, setSku] = useState('');
    const [name, setName] = useState('');
    const [imageSpecsSku, setImageSpecsSku] = useState([]);
    const [imageSpecsName, setImageSpecsName] = useState([]);
    const [imageSpecsUniqueId, setImageSpecsUniqueId] = useState([]);
    const [bundleIdC, setBundleIdC] = useState('');
    const [selectedBundle, setSelectedBundle] = useState(null);
    const [showFrameSelector, setShowFrameSelector] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if(selectedBundle){
            setSelectedFrames(selectedBundle.frames);
            setImageSpecsWidth(selectedBundle.widthPositions);
            setImageSpecsHeight(selectedBundle.heightPositions);
            setImageSpecsTop(selectedBundle.topPositions);
            setImageSpecsLeft(selectedBundle.leftPositions);
            setNetoWidthPositions(selectedBundle.netoWidthPositions);
            setSku(selectedBundle.sku);
            setName(selectedBundle.name);
            setImageSpecsSku(selectedBundle.skuS);
            setImageSpecsName(selectedBundle.nameS);
            setImageSpecsUniqueId(selectedBundle.uniqueIdS);
            setBundleIdC(selectedBundle.bundleId);
        }
    }, [selectedBundle]);

    const handleBundleSelect = (bundle) => {
        setSelectedBundle(bundle);
    };

    const handleFrameSelect = (frame, action) => {
        console.log('Selected frame:', frame);
        if (action === 'add') {
            // Add the frame
            setSelectedFrames([...selectedFrames, frame.url]);
            setImageSpecsWidth([...imageSpecsWidth, frame.width]);
            setImageSpecsHeight([...imageSpecsHeight, frame.height]);
            setImageSpecsTop([...imageSpecsTop, Math.floor(Math.random() * 50)]);
            setImageSpecsLeft([...imageSpecsLeft, Math.floor(Math.random() * 50)]);
            setNetoWidthPositions([...netoWidthPositions, frame.netoWidth]);
            setImageSpecsSku([...imageSpecsSku, frame.sku]);
            setImageSpecsName([...imageSpecsName, frame.name]);
            setImageSpecsUniqueId([...imageSpecsUniqueId, frame.uniqueId]);
        } else if (action === 'remove') {
            // Find the last index of this frame URL and remove it
            const lastIndex = selectedFrames.lastIndexOf(frame.url);
            if (lastIndex !== -1) {
                setSelectedFrames(selectedFrames.filter((_, i) => i !== lastIndex));
                setImageSpecsWidth(imageSpecsWidth.filter((_, i) => i !== lastIndex));
                setImageSpecsHeight(imageSpecsHeight.filter((_, i) => i !== lastIndex));
                setImageSpecsTop(imageSpecsTop.filter((_, i) => i !== lastIndex));
                setImageSpecsLeft(imageSpecsLeft.filter((_, i) => i !== lastIndex));
                setNetoWidthPositions(netoWidthPositions.filter((_, i) => i !== lastIndex));
                setImageSpecsSku(imageSpecsSku.filter((_, i) => i !== lastIndex));
                setImageSpecsName(imageSpecsName.filter((_, i) => i !== lastIndex));
                setImageSpecsUniqueId(imageSpecsUniqueId.filter((_, i) => i !== lastIndex));
            }
        }
    };

    const handleEditBundle = (bundle) => {
        setSelectedBundle(bundle);
        setIsEditMode(true);
        setShowFrameSelector(true);
        setShowFramePlayground(true);
    };

    const handleCloseFramePlayground = () => {
        setShowFramePlayground(false);
        const button = document.querySelector('.create_bundle_btn');
        if (button) {
            button.textContent = 'Open Frames Playground';
        }
        setName('');
        setSku('');
        setSelectedFrames([]);
        setImageSpecsWidth([]);
        setImageSpecsHeight([]);
        setImageSpecsTop([]);
        setImageSpecsLeft([]);
        setNetoWidthPositions([]);
        setImageSpecsSku([]);
        setImageSpecsName([]);
        setImageSpecsUniqueId([]);
        setIsEditMode(false);
    };

    const handleCreateBundle = async () => {
        if (!showFramePlayground) {
            setShowFramePlayground(true); // Show playground instead of frames selection
            return;
        }

        const framePlayground = document.querySelector('.frame_playground');
        let new_tops = [];
        let new_lefts = [];
        
        if (framePlayground) {
            const elements = framePlayground.children;
            Array.from(elements).forEach(element => {
                const top = element.offsetTop;
                const left = element.offsetLeft;
                new_tops.push(top);
                new_lefts.push(left);
            });
            setImageSpecsTop(new_tops);
            setImageSpecsLeft(new_lefts);
        }

        const bundleId = bundleIdC != '' ? bundleIdC : uuidv4();
        const bundle = {
            bundleId: bundleId,
            sku: sku,
            name: name,
            frames: selectedFrames,
            widthPositions: imageSpecsWidth,
            heightPositions: imageSpecsHeight,
            topPositions: new_tops,
            leftPositions: new_lefts,
            netoWidthPositions: netoWidthPositions,
            skuS: imageSpecsSku,
            nameS: imageSpecsName,
            uniqueIdS: imageSpecsUniqueId
        };

        try {
            await writeObjectToFirestore('bundles', bundleId, bundle);
            console.log('Bundle created successfully!');
            handleCloseFramePlayground();
            setShowFrameSelector(false); // Close the popup
        } catch (error) {
            console.error('Error creating bundle:', error);
        }
    };

    const handleBackToFrameSelection = () => {
        setShowFramePlayground(false);
    };

    const toggleFrameSelector = () => {
        setShowFrameSelector(!showFrameSelector);
    };

    return (
        <div className="bundle-upload w-full mx-auto px-4">
            <button
                onClick={() => {
                    setIsEditMode(false);
                    setSelectedBundle(null);
                    setSelectedFrames([]);
                    setImageSpecsWidth([]);
                    setImageSpecsHeight([]);
                    setImageSpecsTop([]);
                    setImageSpecsLeft([]);
                    setNetoWidthPositions([]);
                    setSku('');
                    setName('');
                    setImageSpecsSku([]);
                    setImageSpecsName([]);
                    setImageSpecsUniqueId([]);
                    setBundleIdC('');
                    toggleFrameSelector();
                }}
                className="w-32 bg-gray-900 text-white py-1 rounded-md hover:bg-gray-800 transition duration-200 mb-6"
            >
                Add New Bundle
            </button>

            <BundlesList 
                onBundleSelect={handleBundleSelect} 
                onEditBundle={handleEditBundle}
            />

            <Popup
                triggerText=""
                isOpen={showFrameSelector}
                togglePopup={toggleFrameSelector}
            >
                {!showFramePlayground ? (
                    // Frames Selection View
                    <div className="flex flex-col gap-4">
                        <div className='w-full max-w-4xl p-4 h-72 overflow-scroll'>
                            <FramesSlider 
                                selectedFrames={selectedFrames} 
                                onFrameSelect={handleFrameSelect} 
                                showButtons={false} 
                                bundlesContorls={true}
                            />
                        </div>
                        <button
                            onClick={handleCreateBundle}
                            className="create_bundle_btn mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                        >
                            Open Frames Playground
                        </button>
                    </div>
                ) : (
                    // Frame Playground View
                    <div className="relative w-full">
                        <button
                            onClick={handleBackToFrameSelection}
                            className="absolute top-[-40px] left-0 mt-2 ml-2 px-2 py-1 bg-blue-500 text-white rounded-md z-10"
                        >
                            { isEditMode ? "< Select frames" : "< Back" }
                        </button>
                        <div className="flex flex-wrap mt-6">
                            <div className="flex flex-col w-1/3 p-2">
                                <label className="mb-1 font-semibold">SKU:</label>
                                <input
                                    type="text"
                                    value={sku}
                                    onChange={(e) => setSku(e.target.value)}
                                    required
                                    className="p-2 border rounded"
                                />
                            </div>
                            <div className="flex flex-col w-1/3 p-2">
                                <label className="mb-1 font-semibold">Name:</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    className="p-2 border rounded"
                                />
                            </div>
                        </div>
                        <FramePlayground
                            selectedFrames={selectedFrames}
                            numFrames={selectedFrames.length}
                            widthPositions={imageSpecsWidth}
                            heightPositions={imageSpecsHeight}
                            topPositions={imageSpecsTop}
                            leftPositions={imageSpecsLeft}
                            netoWidthPositions={netoWidthPositions}
                            skuS={imageSpecsSku}
                            nameS={imageSpecsName}
                            uniqueIdS={imageSpecsUniqueId}
                            onRemoveFrame={handleFrameSelect}
                        />
                        <button
                            onClick={handleCreateBundle}
                            className="mt-4 px-4 py-2 bg-green-500 text-white rounded"
                        >
                            Save Bundle
                        </button>
                    </div>
                )}
            </Popup>
        </div>
    );
};

export default BundleUpload;
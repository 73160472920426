import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { getFirestore, doc, setDoc, collection, getDocs, deleteDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBw5sgOF4eO8B-K3eMnPlIu9r6c2Xw2lSg",
    authDomain: "moments-in-light-global.firebaseapp.com",
    projectId: "moments-in-light-global",
    storageBucket: "moments-in-light-global.appspot.com",
    messagingSenderId: "133852955764",
    appId: "1:133852955764:web:e581bf13b20e614a317d21",
    measurementId: "G-7M9GHYWDPL"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

// Function to list all files from a specified path in Firebase Storage
export const listAllFiles = async (path) => {
    const storageRef = ref(storage, path);
    try {
        const res = await listAll(storageRef);
        const files = res.items.map((itemRef) => itemRef.fullPath);
        return files;
    } catch (error) {
        console.error("Error listing files: ", error);
        throw error;
    }
};

// Function to upload a file to Firebase Storage
export const uploadFile = async (path, file, name) => {
    const storageRef = ref(storage, `${path}/${name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};

// Function to delete a file from Firebase Storage
export const deleteFile = async (path, filename) => {
    const fileRef = ref(storage, `${path}/${filename}`);
    try {
        await deleteObject(fileRef);
        return true;
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
};

// Function to download a file from Firebase Storage
export const downloadFile = async (path, name) => {
    const storageRef = ref(storage, `${path}/${name}`);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};

// Function to write an object to Firestore Database
export const writeObjectToFirestore = async (path, name, object) => {
    const docRef = doc(firestore, path, name);
    await setDoc(docRef, object);
};

// Function to edit an object in Firestore Database
export const editObjectInFirestore = async (path, name, object) => {
    const docRef = doc(firestore, path, name);
    await setDoc(docRef, object, { merge: true });
};

// Function to delete an object from Firestore Database
export const deleteObjectFromFirestore = async (path, name) => {
    try {
        const docRef = doc(firestore, path, name);
        await deleteDoc(docRef);
        console.log('Document deleted successfully');
    } catch (error) {
        console.error('Error deleting document:', error);
    }
};

// Function to read an entire collection from Firestore
export const readCollectionFromFirestore = async (collectionName) => {
    try {
        const collectionRef = collection(firestore, collectionName);
        const querySnapshot = await getDocs(collectionRef);
        const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // console.log('Collection data:', documents);
        return documents;
    } catch (error) {
        console.error('Error reading collection: ', error);
        return [];
    }
};

// Function to observe authentication state
export const observeAuthState = (callback) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in
            callback(user);
        } else {
            // User is signed out
            callback(null);
        }
    });
};

export { auth };
import React, { useState } from 'react';
import './FramePlayground.css';

const FramePlayground = ({ selectedFrames, numFrames, widthPositions, heightPositions, topPositions, leftPositions }) => {
    const [frames, setFrames] = useState(
        Array.from({ length: selectedFrames.length }, (_, i) => ({
            id: i,
            top: Number(topPositions[i]),
            left: Number(leftPositions[i]),
            width: Number(widthPositions[i]),
            height: Number(heightPositions[i]),
            backgroundImage: selectedFrames[i] ? selectedFrames[i] : 'none'
        }))
    );
    const [draggingFrame, setDraggingFrame] = useState(null);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    const handleDragStart = (e, frame) => {
        const rect = e.target.getBoundingClientRect();
        setDraggingFrame(frame.id);
        setDragOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        });
        e.dataTransfer.setData('text/plain', frame.id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        const container = e.target.closest('.container'); // Assuming the container has this class
        const containerRect = container.getBoundingClientRect();
        const id = e.dataTransfer.getData('text/plain');
        const newFrames = frames.map(frame => {
            if (frame.id === parseInt(id, 10)) {
                return {
                    ...frame,
                    top: e.clientY - containerRect.top - dragOffset.y,
                    left: e.clientX - containerRect.left - dragOffset.x
                };
            }
            return frame;
        });
        setFrames(newFrames);
        setDraggingFrame(null);
        setDragOffset({ x: 0, y: 0 });
    };

    return (
        <div className="container frame_playground" onDragOver={handleDragOver} onDrop={handleDrop}>
            {frames.map(frame => (
                <div
                    key={frame.id}
                    className="frame"
                    style={{ 
                        width: frame.width, 
                        height: frame.height, 
                        top: frame.top, 
                        left: frame.left, 
                        backgroundColor: draggingFrame === frame.id ? 'lightblue' : 'lightgray', 
                        backgroundImage: `url(${frame.backgroundImage})`, 
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                    draggable
                    onDragStart={(e) => handleDragStart(e, frame)}
                />
            ))}
        </div>
    );
};

export default FramePlayground;
import React, { useState, useEffect } from 'react';
import {uploadFile, deleteFile, deleteObjectFromFirestore, editObjectInFirestore, readCollectionFromFirestore } from '../firebase'; // Adjust the import path as necessary
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import FramesUpload from './FramesUpload';
import Popup from './Popup';
import './FramesSlider.css';

const FramesSlider = ({ onFrameSelect, selectedFrames, showButtons = true, bundlesContorls = false }) => {
  const [frames, setFrames] = useState([]);
  const [showFramesUpload, setShowFramesUpload] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(null);
  
  const toggleFramesUpload = (frame) => {
      setSelectedFrame(frame);
      setShowFramesUpload(!showFramesUpload);      
  };

  const handleDelete = async (frame) => {
      try {
          // Delete the file from Firebase Storage
          await deleteFile(frame.url);

          // Delete the frame object from Firestore
          await deleteObjectFromFirestore('frames', frame.id);

          // Remove the frame from the state
          setFrames((prevFrames) => prevFrames.filter((f) => f.id !== frame.id));
      } catch (error) {
          console.error('Error deleting frame:', error);
      }
  };
  
  const fetchFrames = async () => {
    try {
      const files = await readCollectionFromFirestore('frames');
      setFrames(files);      
    } catch (error) {
      console.error('Error fetching frames:', error);
    }
  };
  useEffect(() => {
    fetchFrames();
    if (window) {
        window.fetchFrames = fetchFrames;
    }
  }, []);

  const handleFrameUpdate = async () => {
    await fetchFrames();
  };

  return (
    <div className="frames-slider w-full h-full">
      {showFramesUpload && (
        <Popup
          triggerText="Update Frame"
          isOpen={showFramesUpload}
          togglePopup={() => toggleFramesUpload(null)}
          onComplete={handleFrameUpdate}
        >
          <FramesUpload 
            frame={selectedFrame}
            togglePopup={() => toggleFramesUpload(null)}
            onComplete={handleFrameUpdate}
          />
        </Popup>
      )}

      <div className="relative h-full">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              <th className="py-2 px-4 bg-gray-200">Frame Name</th>
              <th className="py-2 px-4 bg-gray-200">Image</th>
              {showButtons && (<th className="py-2 px-4 bg-gray-200">Edit</th>)}
              {showButtons && (<th className="py-2 px-4 bg-gray-200">Delete</th>)}
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
            {frames.map((frame, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4">{frame.name}</td>
                <td className="py-2 px-4">
                  <div className="frame-item relative">
                    <img
                      src={frame.url}
                      alt={`Frame ${index}`}
                      className={`h-20 object-contain`}
                    />
                    {bundlesContorls && (
                    <div className="frame-controls flex items-center justify-center gap-2 mt-2">
                        <button 
                            onClick={() => onFrameSelect(frame, 'remove')}
                            disabled={selectedFrames.filter(url => url === frame.url).length === 0}
                            className={`text-white w-8 h-8 rounded-full ${
                                selectedFrames.filter(url => url === frame.url).length === 0 
                                ? 'bg-gray-400 cursor-not-allowed' 
                                : 'bg-red-500 hover:bg-red-700'
                            }`}
                        >
                            -
                        </button>
                        <span className="frame-counter">
                            {selectedFrames.filter(url => url === frame.url).length}
                        </span>
                        <button 
                            onClick={() => onFrameSelect(frame, 'add')}
                            className="bg-green-500 text-white w-8 h-8 rounded-full"
                        >
                            +
                        </button>
                    </div>
                    )}
                  </div>
                </td>
                {showButtons && (
                  <td className="py-2 px-4">
                    <button
                      onClick={() => toggleFramesUpload(frame)}
                      className="bg-green-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                    >
                      Edit
                    </button>
                  </td>
                )}
                {showButtons && (
                  <td className="py-2 px-4">
                    <button
                      onClick={() => handleDelete(frame)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FramesSlider;
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const OrderDetails = ({ order }) => {
    // console.log(order);
    const [showFullImage, setShowFullImage] = useState(false);
    const [activeImage, setActiveImage] = useState(null);
    const handleDownload = (imageUrl, type, index) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = `${order.order_number}_${type}_${index}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    // Helper function to check if item should be displayed
    const shouldDisplayItem = (itemKey) => {
        return order[itemKey] && (!order.item_src || order.item_src === 'framelie');
    };
    const getBackgroundColor = (index) => {
        return index % 2 === 0 ? '#a3caec' : '#d8a45f'; // Alternating colors
    };

    const downloadAllCroppedImages = async () => {
        const zip = new JSZip();
        
        order.toPrint.forEach((item, index) => {
            if (item.data.type === 'bundle') {
                Object.values(item.data.customized_frames || {}).forEach((image, imgIndex) => {
                    zip.file(`${order.order_number}_bundle_${index}_cropped_${imgIndex}.jpg`, fetch(image).then(res => res.blob()));
                });
            } else if (item.data.cropped_image_url) {
                zip.file(`${order.order_number}_${item.data.current_frame_name}_item_${index}_cropped.jpg`, fetch(item.data.cropped_image_url).then(res => res.blob()));
            }
        });

        const zipFile = await zip.generateAsync({ type: 'blob' });
        saveAs(zipFile, `${order.order_number}_cropped_images.zip`);
    };

    return (
        <div className="order-details"
        style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <style>
                {`
                    tr {
                        padding: 10px;
                        margin: 5px 0;
                    }
                    th, td {
                        padding: 10px;
                    }
                    .image-buttons {
                        display: flex;
                        justify-content: center;
                        gap: 10px;
                        margin-top: 10px;
                    }

                    .image-buttons button {
                        padding: 10px 20px;
                        border: none;
                        background-color: #007bff;
                        color: white;
                        cursor: pointer;
                        border-radius: 5px;
                        font-size: 14px;
                        transition: background-color 0.3s;
                    }

                    .image-buttons button:hover {
                        background-color: #0056b3;
                    }
                `}
            </style>
            <h2>Order Details</h2>
            <div className="mb-4">
                <button
                    onClick={downloadAllCroppedImages}
                    className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                >
                    Download All Cropped Images
                </button>
            </div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <th>Order Number</th>
                        <td>{order.order_number}</td>
                        <th>Order Number</th>
                    </tr>
                    <tr>
                        <th>Order ID</th>
                        <td>{order.oid}</td>
                        <th>Order ID</th>
                    </tr>
                    {/* <tr>
                        <th>Event Name</th>
                        <td>{order.event_name}</td>
                        <th>Event Name</th>
                    </tr> */}
                    <tr>
                        <th>Total Price</th>
                        <td>{order.totalPrice}</td>
                        <th>Total Price</th>
                    </tr>
                    {/* <tr>
                        <th>First Item Title</th>
                        <td>{order.firstItem?.title}</td>
                        <th>First Item Title</th>
                    </tr>
                    <tr>
                        <th>Payment Gateway</th>
                        <td>{order.paymentTransactions[0]?.paymentGateway}</td>
                        <th>Payment Gateway</th>
                    </tr>
                    <tr>
                        <th>Payment Amount</th>
                        <td>{order.paymentTransactions[0]?.amount.amount}</td>
                        <th>Payment Amount</th>
                    </tr>
                    <tr>
                        <th>Currency Code</th>
                        <td>{order.paymentTransactions[0]?.amount.currencyCode}</td>
                        <th>Currency Code</th>
                    </tr> */}
                    {
                        order.toPrint.map((item, itemIndex) => {

                            const backgroundColor = getBackgroundColor(itemIndex);

                            return item.data.type === 'bundle' ? (
                                <React.Fragment key={`bundle-${itemIndex}`}>
                                    <tr style={{ backgroundColor }}>
                                        <th>SKU</th>
                                        <td>{item.data.sku}</td>
                                        <th>SKU</th>
                                    </tr>
                                    <tr style={{ backgroundColor }}>
                                        <th>NAME</th>
                                        <td>{item.data.current_frame_name}</td>
                                        <th>NAME</th>
                                    </tr>
                                    {Object.values(item.data.original_images || {}).map((image, index) => (
                                        <tr key={`original-${itemIndex}-${index}`} style={{ backgroundColor }}>
                                            <th>Original Image {index + 1}</th>
                                            <td>
                                                <div className="image-section">
                                                    <img 
                                                        src={image} 
                                                        alt={`Order ${index + 1}`} 
                                                        style={{ height: '100px', objectFit: 'cover' }} 
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="image-section">
                                                    <div className="image-buttons">
                                                        <button onClick={() => {
                                                            setShowFullImage(true);
                                                            setActiveImage(`original-${itemIndex}-${index}`);
                                                        }}>Preview</button>
                                                        <button onClick={() => handleDownload(image, 'original', index)}>Download</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {Object.values(item.data.customized_frames || {}).map((image, index) => (
                                        <tr key={`cropped-${itemIndex}-${index}`} style={{ backgroundColor }}>
                                            <th>Customized Frame {index + 1}</th>
                                            <td>
                                                <div className="image-section">
                                                    <img 
                                                        src={image} 
                                                        alt={`Cropped Order ${index + 1}`} 
                                                        style={{ height: '100px', objectFit: 'cover' }} 
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="image-section">
                                                    <div className="image-buttons">
                                                        <button onClick={() => {
                                                            setShowFullImage(true);
                                                            setActiveImage(`cropped-${itemIndex}-${index}`);
                                                        }}>Preview</button>
                                                        <button onClick={() => handleDownload(image, 'cropped', index)}>Download</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {(
                                        <tr key={`preview-${itemIndex}`} style={{ backgroundColor }}>
                                            <th>Preview Image</th>
                                            <td>
                                                <div className="image-section">
                                                    <img 
                                                        src={item.data.preview_image} 
                                                        alt="Preview Order" 
                                                        style={{ height: '100px', objectFit: 'cover' }} 
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="image-section">
                                                    <div className="image-buttons">
                                                        <button onClick={() => {
                                                            setShowFullImage(true);
                                                            setActiveImage(`preview-${itemIndex}`);
                                                        }}>Preview</button>
                                                        <button onClick={() => handleDownload(item.data.preview_image, 'preview', itemIndex)}>Download</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`item-${itemIndex}`}>
                                    <tr style={{ backgroundColor }}>
                                        <th>SKU</th>
                                        <td>{item.data.sku}</td>
                                        <th>SKU</th>
                                    </tr>
                                    <tr style={{ backgroundColor }}>
                                        <th>NAME</th>
                                        <td>{item.data.current_frame_name}</td>
                                        <th>NAME</th>
                                    </tr>
                                    {(
                                        <tr key={`original-${itemIndex}`} style={{ backgroundColor }}>
                                            <th>Image</th>
                                            <td>
                                                <div className="image-section">
                                                    <img 
                                                        src={item.data.image_src} 
                                                        alt="Order" 
                                                        style={{ height: '100px', objectFit: 'cover' }} 
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="image-section">
                                                    <div className="image-buttons">
                                                        <button onClick={() => {
                                                            setShowFullImage(true);
                                                            setActiveImage(`image-${itemIndex}`);
                                                        }}>Preview</button>
                                                        <button onClick={() => handleDownload(item.data.image_src, item.data.current_frame_name + 'image', itemIndex)}>Download</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {(
                                        <tr key={`cropped-${itemIndex}`} style={{ backgroundColor }}>
                                            <th>Cropped Image</th>
                                            <td>
                                                <div className="image-section">
                                                    <img 
                                                        src={item.data.cropped_image_url} 
                                                        alt="Cropped Order" 
                                                        style={{ height: '100px', objectFit: 'cover' }} 
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="image-section">
                                                    <div className="image-buttons">
                                                        <button onClick={() => {
                                                            setShowFullImage(true);
                                                            setActiveImage(`cropped_image-${itemIndex}`);
                                                        }}>Preview</button>
                                                        <button onClick={() => handleDownload(item.data.cropped_image_url, item.data.current_frame_name + 'cropped_image', itemIndex)}>Download</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )
})
                    }
                </tbody>
            </table>
            {showFullImage && (
                <div className="modal" 
                     style={{
                         position: 'fixed',
                         top: 0,
                         left: 0,
                         right: 0,
                         bottom: 0,
                         backgroundColor: 'rgba(0,0,0,0.7)',
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         zIndex: 1000
                     }}>
                    <div className="modal-content">
                    <img 
                        src={
                            activeImage?.startsWith('original-')
                                ? order.toPrint[parseInt(activeImage.split('-')[1])].data.original_images[parseInt(activeImage.split('-')[2])]
                                : activeImage?.startsWith('cropped-')
                                    ? order.toPrint[parseInt(activeImage.split('-')[1])].data.customized_frames[parseInt(activeImage.split('-')[2])]
                                    : activeImage?.startsWith('preview-')
                                        ? order.toPrint[parseInt(activeImage.split('-')[1])].data.preview_image
                                        : activeImage?.startsWith('image-')
                                            ? order.toPrint[parseInt(activeImage.split('-')[1])].data.image_src
                                            : activeImage?.startsWith('cropped_image-')
                                                ? order.toPrint[parseInt(activeImage.split('-')[1])].data.cropped_image_url
                                                : ''
                        } 
                        alt="Order Full Size"
                        style={{ maxWidth: '90%', maxHeight: '90vh' }}
                    />
                        <button 
                            onClick={() => {
                                setShowFullImage(false);
                                setActiveImage(null);
                            }}
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                background: 'white',
                                padding: '10px'
                            }}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

OrderDetails.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderDetails;